<template>
  <div>
    <b-row class="match-height">
      <!--TRIP-->
      <b-col md="8">
        <TripEditFlight ref="editFlightRef" @onEventType="eventType = $event" />
      </b-col>

      <!--AIRCRAFT-->
      <b-col :class="['aircraft-edit', { 'disabled-item': eventType === 'transfer' }]" md="4">
        <TripEditAircraft ref="editAircraftRef" />
      </b-col>
    </b-row>

    <!-- SAVE -->
    <b-row class="mb-2">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button @click="saveTripInformations">
          <font-awesome-icon class="mr-50" icon="save" />
          {{ $t('action.save') }}
        </b-button>
      </b-col>
    </b-row>

    <SidebarAddEvent v-if="$can('TRIP_EDIT')" :is-add-new-event-sidebar-active.sync="isAddNewEventSidebarActive" :trip-data="tripData" />
  </div>
</template>

<script>
import TripEditFlight from '@/views/trip/trip-edit/TripEditFlight.vue'
import TripEditAircraft from '@/views/trip/trip-edit/TripEditAircraft.vue'
import SidebarAddEvent from '@/views/timeline/sidebar/SidebarAddEvent.vue'

export default {
  name: 'TripEdit',

  components: {
    TripEditAircraft,
    TripEditFlight,
    SidebarAddEvent,
  },
  data() {
    return {
      isAddNewEventSidebarActive: false,
      eventType: null,
      tripData: {
        name: '',
        tripType: '',
        flightMission: '',
        passengers: 0,
        crew: 0,
        organizationAircraftId: 0,
      },
    }
  },
  methods: {
    async saveTripInformations() {
      const flightForm = await this.$refs.editFlightRef.validationForm()
      if (!flightForm) return

      let tripData = {
        ...this.tripData,
        ...this.$refs.editFlightRef.flightData,
      }
      let aircraftForm = null
      let dataAircraft = null

      if (this.eventType !== 'transfer') {
        aircraftForm = await this.$refs.editAircraftRef.validationForm()
        dataAircraft = this.$refs.editAircraftRef.organizationAircraftsData
        tripData = {
          ...tripData,
          ...{ organizationAircraft: dataAircraft },
          organizationAircraftId: dataAircraft.id,
        }
      }
      this.tripData = tripData

      if (this.eventType !== 'transfer' && flightForm && aircraftForm) {
        this.isAddNewEventSidebarActive = true
      } else if (this.eventType === 'transfer' && flightForm) {
        this.isAddNewEventSidebarActive = true
      }
    },
  },
}
</script>
<style scoped>
.aircraft-edit {
  transition: 0.3s ease-in-out all;
}
.disabled-item {
  filter: blur(2px) contrast(0.6);
  z-index: -1;
}
</style>
