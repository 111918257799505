<template>
  <b-sidebar
    id="sidebar-addEvent"
    :visible="isAddNewEventSidebarActive"
    @change="$emit('update:is-add-new-event-sidebar-active', $event)"
  >
    <template #default="{ hide }">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('trip.sidebar.add_an_event') }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <validation-observer ref="addOrganizationUser" #default="{ handleSubmit }">
        <!-- Form -->
        <b-form v-if="eventData" ref="formEventRules" class="p-2" @submit.prevent="handleSubmit(onSubmit)">
          <b-form-group v-if="eventData.type === 'flight'" :label="$t('trip.event.trip_type')" label-for="trip-type">
            <b-form-radio-group
              id="trip-type"
              v-model="legSelected"
              :disabled="legOptionsDisabled"
              :options="legOptions"
            />
          </b-form-group>

          <div v-if="tripData && tripData.id && tripData.tripType === 'flight'">
            <b-form-group v-if="eventData.type" :label="$t('trip.event.type')" label-for="trip-eventType">
              <b-form-radio-group
                id="trip-eventType"
                v-model="eventData.type"
                :disabled="legOptionsDisabled || tripData.tripType === 'transfer'"
                :options="eventTypeOptions"
              >
                <div class="font-italic font-weight-light font-small-2 mt-50">
                  {{ $t('trip.event.note') }}
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>

          <section v-show="eventData.type">
            <div v-show="eventData.type !== 'transfer'" class="font-small-3">
              <span class="font-weight-bold">{{ $t('trip.aircraft_model') }} : </span>
              <span v-if="tripData && tripData.organizationAircraft" class="font-weight-light">
                {{
                  tripData.organizationAircraft.nameInfo ? tripData.organizationAircraft.nameInfo : tripData.organizationAircraft.name
                }}
              </span>
            </div>

            <div class="mb-1 font-small-3">
              <span class="font-weight-bold">{{ $t('trip.event.type') }} : </span>
              <span class="font-weight-light">{{ eventData.type | enumTranslate('trip_type') }}</span>
            </div>
          </section>

          <hr class="my-1" />
          <!--Departure-->
          <validation-provider class="validation-required">
            <b-form-group :label="$t('trip.event.departure')" label-for="departure-city">
              <GmapAutocomplete
                id="departure-city"
                ref="gmapDeparture"
                class="form-control"
                @place_changed="setDeparturePlace"
              />
              <small v-show="!isValidDepartureAddress" class="text-danger">{{
                $t('trip.event.departure_address_error')
              }}</small>

              <p v-if="departureLatitude && departureLongitude">
                <span class="font-italic">{{ $t('common.note') }}</span> {{ $t('trip.shop.gps_note') }}
                {{ departureLatitude }} , {{ departureLongitude }}
              </p>
            </b-form-group>
          </validation-provider>

          <b-row>
            <b-col cols="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('trip.event.departure_date')"
                class="validation-required"
                rules="required"
              >
                <b-form-group :label="$t('trip.event.departure_date')" class="mb-1" label-for="departure-date">
                  <b-form-datepicker
                    id="departure-date"
                    v-model="eventData.departure.date"
                    :locale="$i18n.locale"
                    :max="eventData.arrival.date"
                    :min="new Date()"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                    size="md"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('trip.event.etd_utc')"
                class="validation-required"
                rules="required"
              >
                <b-form-group :label="$t('trip.event.etd_utc')" class="mb-50" label-for="departure-time">
                  <b-form-timepicker
                    v-model="eventData.departure.time"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                    size="md"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <span v-if="eventData.departure.date && eventData.departure.time" class="ml-25">
                {{ $t('common.local_time') }} :
                {{
                  $moment
                    .utc(eventData.departure.date + ' ' + eventData.departure.time)
                    .utcOffset(departureAddress.timeZone)
                    .format('ddd, MMM Do YYYY HH:mm')
                }}
              </span>
            </b-col>
          </b-row>

          <hr class="my-2" />

          <!-- ARRIVAL -->
          <b-form-group :label="$t('trip.event.arrival')" label-for="arrival-city">
            <GmapAutocomplete
              id="arrival-city"
              ref="gmapArrival"
              class="form-control"
              @place_changed="setArrivalPlace"
            />
            <small v-show="!isValidArrivalAddress" class="text-danger">{{
              $t('trip.event.arrival_address_error')
            }}</small>
            <p v-if="arrivalLatitude && arrivalLongitude">
              <span class="font-italic">{{ $t('common.note') }}</span> {{ $t('trip.shop.gps_note') }}
              {{ arrivalLatitude }} , {{ arrivalLongitude }}
            </p>
          </b-form-group>

          <b-row v-if="eventData && eventData.type !== 'transfer'">
            <b-col cols="6">
              <!-- ARRIVAL DATE-->
              <validation-provider
                #default="{ errors }"
                :name="$t('trip.event.arrival_date')"
                class="validation-required"
                rules="required"
              >
                <b-form-group :label="$t('trip.event.arrival_date')" class="mb-1" label-for="arrival-date">
                  <b-form-datepicker
                    id="arrival-date"
                    v-model="eventData.arrival.date"
                    :locale="$i18n.locale"
                    :min="eventData.departure.date"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                    size="md"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6">
              <!-- ARRIVAL HOUR UTC-->
              <validation-provider
                #default="{ errors }"
                :name="$t('trip.event.eta_utc')"
                class="validation-required"
                rules="required"
              >
                <b-form-group :label="$t('trip.event.eta_utc')" class="mb-50" label-for="arrival-time">
                  <b-form-timepicker
                    v-model="eventData.arrival.time"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                    size="md"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <span v-if="eventData.arrival.date && eventData.arrival.time" class="ml-25">
                {{ $t('common.local_time') }} :
                {{
                  $moment
                    .utc(eventData.arrival.date + ' ' + eventData.arrival.time)
                    .utcOffset(arrivalAddress.timeZone)
                    .format('ddd, MMM Do YYYY HH:mm')
                }}
              </span>
            </b-col>
          </b-row>

          <hr class="my-2" />

          <!--IF TRANSFER-->
          <section v-if="eventData && eventData.type === 'transfer'">
            <b-row>
              <!-- Event type -->
              <b-col cols="12">
                <enum-select
                  v-model="eventData.departure.engineType"
                  :label="$t('trip.event.transfer_helicopter.engine_type')"
                  enum-class="EngineType"
                  input-id="engineType"
                  required
                />
              </b-col>
            </b-row>

            <b-row>
              <!--PASSENGERS NUMBER-->
              <b-col cols="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$tc('trip.event.passenger_number', eventData.departure.passengersNumber)"
                >
                  <b-form-group
                    :label="$tc('trip.event.passenger_number', eventData.departure.passengersNumber)"
                    label-for="passengerFlightNumber"
                  >
                    <spin-input
                      id="'passengerFlightNumber"
                      :value="eventData.departure.passengersNumber ? eventData.departure.passengersNumber : 0"
                      @input="eventData.departure.passengersNumber = $event"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <!--LUGGAGE NUMBER-->
              <b-col>
                <validation-provider
                  #default="{ errors }"
                  :name="$tc('trip.event.luggage_number', eventData.departure.luggagesNumber)"
                >
                  <b-form-group
                    :label="$tc('trip.event.luggage_number', eventData.departure.luggagesNumber)"
                    label-for="luggage-number"
                  >
                    <spin-input
                      id="'luggage-number"
                      :value="eventData.departure.luggagesNumber ? eventData.departure.luggagesNumber : 0"
                      @input="eventData.departure.luggagesNumber = $event"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!--BAGS NUMBER-->
              <b-col>
                <validation-provider
                  #default="{ errors }"
                  :name="$tc('trip.event.bag_number', eventData.departure.bagsNumber)"
                >
                  <b-form-group
                    :label="$tc('trip.event.bag_number', eventData.departure.bagsNumber)"
                    label-for="bag-number"
                  >
                    <spin-input
                      id="'bag-number"
                      :value="eventData.departure.bagsNumber ? eventData.departure.bagsNumber : 0"
                      @input="eventData.departure.bagsNumber = $event"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <!--SEPARATED LUGGAGE -->
              <b-col>
                <enum-radio
                  id="separatedLuggage"
                  v-model="eventData.departure.separatedLuggage"
                  :label="$t('trip.event.separated_luggage')"
                  :values="[
                    {value: null, text: $t('trip.event.transfer_helicopter.choose_later')},
                    {value: true, text: $t('common.yes')},
                    {value: false, text: $t('common.no')}
                  ]"
                />
              </b-col>
            </b-row>
          </section>

          <!--IF FLIGHT -->
          <section v-if="eventData && eventData.type === 'flight'">
            <b-row>
              <!--PASSENGERS NUMBER-->
              <b-col cols="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$tc('trip.event.passenger_number', eventData.departure.passengersNumber)"
                >
                  <b-form-group
                    :label="$tc('trip.event.passenger_number', eventData.departure.passengersNumber)"
                    label-for="passengerFlightNumber"
                  >
                    <spin-input
                      id="'passengerFlightNumber"
                      :value="eventData.departure.passengersNumber ? eventData.departure.passengersNumber : 0"
                      @input="eventData.departure.passengersNumber = $event"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$tc('trip.event.crew_number', eventData.departure.crewsNumber)"
                >
                  <b-form-group
                    :label="$tc('trip.event.crew_number', eventData.departure.crewsNumber)"
                    label-for="crew-number"
                  >
                    <spin-input
                      id="'crew-number"
                      :value="eventData.departure.crewsNumber ? eventData.departure.crewsNumber : 0"
                      @input="eventData.departure.crewsNumber = $event"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <!-- Event type -->
                <enum-select
                  v-model="eventData.departure.flightType"
                  :label="$t('trip.event.flight_type')"
                  enum-class="FlightType"
                  input-id="eventFlightType"
                  required
                />
              </b-col>
              <b-col cols="6">
                <!-- Event type -->
                <enum-select
                  v-model="eventData.departure.flightMission"
                  :label="$t('trip.event.flight_mission')"
                  enum-class="FlightMission"
                  input-id="departure"
                  required
                />
              </b-col>
            </b-row>
          </section>

          <!--VALIDATE FORM -->
          <div class="d-flex mt-2 justify-content-end flex-column">
            <b-button
              v-if="legSelected === 'oneLeg' || (legSelected === 'roundTrip' && firstLegValidated) || (legSelected === 'multiLegs' && firstLegValidated)"
              :disabled="(!arrivalAddress && !arrivalAddressInline) || (!departureAddress && !departureAddressInline)"
              block
              class="mr-2"
              type="submit"
              @click.prevent="
                validationForm()
                goToTrip = true
              "
            >
              <font-awesome-icon class="mr-50" icon="calendar-plus" />
              {{ $t('trip.event.validate_trip_timeline') }}
            </b-button>

            <b-button
              v-if="legSelected === 'roundTrip' && !firstLegValidated"
              block
              class="mr-2"
              type="submit"
              @click.prevent="
                validationForm()
                goToTrip = false
              "
            >
              <font-awesome-icon class="mr-50" icon="calendar-plus" />
              {{ $t('trip.return_flight.action.add') }}
            </b-button>

            <b-button
              v-if="legSelected === 'multiLegs'"
              block
              class="mr-2"
              type="submit"
              @click.prevent="
                validationForm()
                goToTrip = false
              "
            >
              <font-awesome-icon class="mr-50" icon="calendar-plus" />
              {{ $t('trip.action.add_another_leg') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              block
              variant="outline-secondary"
              @click="
                hide()
                resetAll()
              "
            >
              <font-awesome-icon class="mr-50" icon="ban" />
              {{ $t('action.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { intersection, upperFirst } from 'lodash'

import SpinInput from '@/components/SpinInput.vue'
import EnumRadio from '@/components/EnumRadio.vue'
import { postTripRequest } from '@/request/globalApi/requests/tripRequest'
import { postTripStepFlightRequest, postTripStepTransferRequest } from '@/request/globalApi/requests/tripStepRequests'

export default {
  name: 'SidebarAddEvent',

  components: {
    SpinInput,
    EnumRadio,
  },

  model: {
    prop: 'isAddNewEventSidebarActive',
    event: 'update:is-add-new-event-sidebar-active',
  },
  props: {
    isAddNewEventSidebarActive: {
      type: Boolean,
      required: true,
    },
    tripData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tripId: null,
      departureAddressInline: null,
      isValidDepartureAddress: true,
      departureAddress: null,
      arrivalAddressInline: null,
      isValidArrivalAddress: true,
      arrivalAddress: null,
      departureLatitude: null,
      departureLongitude: null,
      arrivalLatitude: null,
      arrivalLongitude: null,
      legSelected: 'oneLeg',
      goToTrip: false,
      legOptionsDisabled: false,
      firstLegValidated: false,
      airportOptions: null,
      legOptions: [
        { text: this.$t('trip.event.one_leg'), value: 'oneLeg' },
        { text: this.$t('trip.event.round_trip'), value: 'roundTrip' },
        { text: this.$t('trip.event.multi_legs'), value: 'multiLegs' },
      ],
      eventTypeOptions: [
        { text: this.$t('trip.event.flight_departure_and_arrival'), value: 'flight' },
        { text: this.$t('trip.event.helicopter_transport'), value: 'transfer' },
      ],
      eventDataSkeleton: {
        engineType: '',
        departure: {
          city: '',
          date: '',
          time: '',
          flightMission: '',
          flightType: '',
          passengersNumber: 0,
          crewsNumber: 0, // this.tripData.organizationAircraft.minFlightCrew,
          luggagesNumber: 0,
          bagsNumber: 0,
          disabledNumber: 0,
          separatedLuggage: null,
        },
        arrival: {
          city: '',
          date: '',
          time: '',
        },
      },
      eventData: {
        type: 'flight',
      },
      tripStepBlank: {
        tripId: null,
        departureAddressId: null,
        departureDateTime: '',
        arrivalAddressId: null,
        arrivalDateTime: '',
        flightType: '',
        flightMission: '',
        departureAirportId: null,
        arrivalAirportId: null,
        passengers: 0,
        // AIRCRAFT
        crew: 0,
        // HELICOPTER
        luggage: 0,
        bags: 0,
        engineType: null,
      },
    }
  },
  watch: {
    isAddNewEventSidebarActive() {
      this.resetEventType()
    },
  },
  mounted() {
    this.resetEventType()
  },
  methods: {
    upperFirst,
    async APIPostTrip() {
      const tripToSend = {
        name: this.tripData.name,
        tripType: this.eventData.type,
        passengers: this.eventData.departure.passengersNumber,
        crew: this.eventData.departure.crewsNumber,
        flightMission: this.eventData.departure.flightMission || null,
        organizationAircraftId: this.tripData.organizationAircraftId || null,
      }

      const payload = {
        trip: tripToSend,
      }
      postTripRequest(payload).then(response => {
        this.tripId = response.data.id
        this.APIPostTripStep()
      })
    },
    async APIPostTripStep() {
      this.legOptionsDisabled = true
      this.tripId = this.tripId || this.$route.params.trip_id

      const tripStepToSend = {
        ...this._cloneDeep(this.tripStepBlank),
        tripId: this.tripId,
        // DEPARTURE
        departureAddress: this.departureAddress,
        departureAddressInline: this.departureAddressInline,
        departureLongitude: this.departureLongitude,
        departureLatitude: this.departureLatitude,
        departureDateTime: `${this.eventData.departure.date} ${this.eventData.departure.time}`,
        // ARRIVAL
        arrivalAddress: this.arrivalAddress,
        arrivalAddressInline: this.arrivalAddressInline,
        arrivalLongitude: this.arrivalLongitude,
        arrivalLatitude: this.arrivalLatitude,
        arrivalDateTime: `${this.eventData.arrival.date} ${this.eventData.arrival.time}`,
        passengers: this.eventData.departure.passengersNumber,
      }

      if (this.eventData.type === 'flight') {
        // AIRCRAFT
        tripStepToSend.flightMission = this.eventData.departure.flightMission
        tripStepToSend.flightType = this.eventData.departure.flightType
        tripStepToSend.departureAirportId = this.eventData.departure.city.id
        tripStepToSend.arrivalAirportId = this.eventData.arrival.city.id
        tripStepToSend.crew = this.eventData.departure.crewsNumber
      } else {
        // HELICOPTER
        tripStepToSend.luggage = this.eventData.departure.luggagesNumber
        tripStepToSend.bags = this.eventData.departure.bagsNumber
        tripStepToSend.separatedLuggage = this.eventData.departure.separatedLuggage
        tripStepToSend.engineType = this.eventData.engineType
      }

      const payload = {
        tripStep: tripStepToSend,
      }

      const action = this.eventData.type === 'flight' ? postTripStepFlightRequest : postTripStepTransferRequest

      action(payload).then(() => {
        this.redirectRoute()
      })
    },
    onSubmit() {
      if (!this.tripData.id && !this.firstLegValidated) {
        this.APIPostTrip()
      } else {
        this.APIPostTripStep()
      }
    },
    redirectRoute() {
      let nextRoute = { name: 'trip-view', params: { trip_id: this.tripId } }

      if (this.tripData.id) {
        nextRoute = { name: 'trip-view', params: { trip_id: this.tripId } }
      }

      if (this.legSelected === 'oneLeg') {
        this.resetAll()
        this.$emit('update:is-add-new-event-sidebar-active', false)
        this.legOptionsDisabled = false
        this.firstLegValidated = false
        this.$router.push(nextRoute)
      }

      if (['roundTrip', 'multiLegs'].includes(this.legSelected)) {
        const copyDepartureAddressInline = this.departureAddressInline
        const copyDepartureAddress = this._cloneDeep(this.departureAddress)
        const copyArrivaleAddressInline = this.arrivalAddressInline
        const copyArrivalAddress = this._cloneDeep(this.arrivalAddress)

        this.resetEventType()

        // Swith place departure/arrival
        this.departureAddressInline = copyArrivaleAddressInline
        this.arrivalAddressInline = copyDepartureAddressInline
        this.departureAddress = copyArrivalAddress
        this.arrivalAddress = copyDepartureAddress
        document.getElementById('departure-city').value = copyArrivaleAddressInline
        document.getElementById('arrival-city').value = copyDepartureAddressInline

        if (this.firstLegValidated) {
          this.legOptionsDisabled = false
          if (this.goToTrip) {
            this.$emit('update:is-add-new-event-sidebar-active', false)
            this.$router.push(nextRoute)
            this.goToTrip = false
            this.firstLegValidated = false
            this.resetEventType()
          }
        } else {
          this.firstLegValidated = true
        }
      }
      this.$emit('refetch-data')
    },
    resetAll() {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.resetValidator()
    },
    resetValidator() {
      requestAnimationFrame(() => {
        if (this.$refs.addOrganizationUser) {
          this.$refs.addOrganizationUser.reset()
        }
      })
    },
    resetEventType() {
      this.eventData.departure = this._cloneDeep(this.eventDataSkeleton.departure)
      this.eventData.arrival = this._cloneDeep(this.eventDataSkeleton.arrival)
      if (this.tripData.eventType) {
        this.tripData.tripType = this.tripData.eventType
      }

      if (this.tripData.tripType) {
        this.eventData.type = this.tripData.tripType
      }

      this.resetValidator()
    },
    validationForm() {
      this.$refs.addOrganizationUser.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
    reset() {
      this.eventData = this._cloneDeep(this.eventDataSkeleton)
      this.legOptionsDisabled = false
      this.firstLegValidated = false
    },
    setAddress(place, type) {
      let streetNumber = null
      let route = null
      let postalCode = null
      let country = null
      let locality = null
      let address = null
      const timeZone = place.utc_offset_minutes

      if (place.types.includes('airport')) locality = 'airport'

      place.address_components.forEach(addr => {
        if (addr.types.includes('street_number')) streetNumber = addr.short_name
        else if (addr.types.includes('route')) route = addr.short_name
        else if (addr.types.includes('postal_code')) postalCode = addr.short_name
        else if (addr.types.includes('country')) country = addr.short_name
        else if (intersection(addr.types, ['locality', 'postal_town', 'sublocality_level_1', 'route']).length) {
          locality = addr.short_name
        }
      })

      if (route) {
        streetNumber ? (address = `${streetNumber} ${route}`) : (address = route)
      } else {
        address = place.name
      }

      if (address && country && locality) {
        if (type === 'departure') {
          this.isValidDepartureAddress = true
          this.errorMessageDepartureAddress = null
          this.departureAddress = {
            address,
            postalCode: postalCode ?? '00000',
            city: locality,
            countryCode: country,
            timeZone,
          }
          this.departureAddressInline = place.formatted_address
          this.departureLatitude = place.geometry.location.lat()
          this.departureLongitude = place.geometry.location.lng()
        } else {
          this.isValidArrivalAddress = true
          this.arrivalAddress = {
            address,
            postalCode: postalCode ?? '00000',
            city: locality,
            countryCode: country,
            timeZone,
          }
          this.arrivalAddressInline = place.formatted_address
          this.arrivalLatitude = place.geometry.location.lat()
          this.arrivalLongitude = place.geometry.location.lng()
        }
      } else if (type === 'departure') {
        this.isValidDepartureAddress = false
      } else {
        this.isValidArrivalAddress = false
      }
    },
    setDeparturePlace(place) {
      this.departureAddressInline = null
      this.departureAddress = null
      this.setAddress(place, 'departure')
    },
    setArrivalPlace(place) {
      this.arrivalAddressInline = null
      this.arrivalAddress = null
      this.setAddress(place, 'arrival')
    },
  },
}
</script>

<style lang="scss">
.pac-container {
  z-index: 2000;
}
</style>
