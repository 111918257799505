import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/trip-step'

// TRIP STEP
export const fetchTripStepsRequest = payload => globalApi.get(`${END_POINT}`, { params: payload })

// WARNING soft delete
export const deleteTripStepRequest = (id, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${id}/delete`, { message }),
)

// WARNING hard delete
export const removeTripStepRequest = (id, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${id}/remove`, { message }),
)

// STEP FLIGHT
export const postTripStepFlightRequest = ({
  tripStep,
  message,
}) => globalApi.post(`${END_POINT}-flight`, tripStep, { message })

// STEP TRANSFER
export const postTripStepTransferRequest = ({
  tripStep,
  message,
}) => globalApi.post(`${END_POINT}-transfer`, tripStep, { message })

// DISABLED PARTICIPANT
export const patchTripStepDisabledParticipantRequest = (tripStepId, disabledParticipant, message) => globalApi.patch(`${END_POINT}/${tripStepId}/disabled-participant`, disabledParticipant, { message })

// FLIGHT PLAN
export const patchTripStepFlightPlanRequest = (tripStepId, number, message) => globalApi.patch(`${END_POINT}/${tripStepId}/flight-plan`, number, { message })

// EDIT FLIGHT LEG
export const patchTripStepFlightRequest = (tripStepId, leg, message) => globalApi.patch(`${END_POINT}-flight/${tripStepId}`, leg, { message })

// EDIT TRANSFER LEG
export const patchTripStepTransferRequest = (tripStepId, leg, message) => globalApi.patch(`${END_POINT}-transfer/${tripStepId}`, leg, { message })

// VALIDATE AIRPORT
export const patchTripStepValidateAirportRequest = (tripStepId, legId, leg, message) => globalApi.patch(`${END_POINT}/${tripStepId}/validate-airport/${legId}`, leg, {
  message,
})

// VALIDATE
export const patchTripStepSlotValidateRequest = (tripStepId, event, message) => globalApi.patch(`${END_POINT}/${tripStepId}/slot/${event.id}/validate`, {
  message,
})

// SLOT AIRPORT
export const patchTripStepSlotAirportRequest = (tripStepId, legId, leg, message) => globalApi.patch(`${END_POINT}/${tripStepId}/slot/${legId}`, leg, { message })

// SLOT
export const patchTripStepSlotRequest = (tripStepId, eventId, slot, message) => globalApi.patch(`${END_POINT}/${tripStepId}/slot/${eventId}`, slot, {
  message,
})

// PARTICIPANT
export const fetchTripStepParticipantsRequest = (id, message) => globalApi.get(`${END_POINT}/${id}/participant`, { message })

export const fetchTripStepParticipantRequest = (tripStepId, id, message) => globalApi.get(`${END_POINT}/${tripStepId}/participant/${id}`, { message })

export const postTripStepParticipantRequest = (tripStepId, participant, message) => globalApi.post(`${END_POINT}/${tripStepId}/participant`, participant, {
  message,
})

export const patchTripStepParticipantRequest = (tripStepId, participant, message) => globalApi.patch(
  `${END_POINT}/${tripStepId}/participant/${participant.id}`,
  participant,
  { message },
)

export const deleteTripStepParticipantRequest = (tripStepId, id, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed
    && globalApi.delete(`${END_POINT}/${tripStepId}/participant/${id}`, {
      message,
    }),
)

// LEG COST
export const fetchTripStepCostDetailsRequest = tripStepId => globalApi.get(`${END_POINT}/${tripStepId}/cost-details`)

// PROVIDER
export const fetchTripStepProvidersRequest = tripStepId => globalApi.get(`${END_POINT}/${tripStepId}/provider`)

// PPR
export const fetchTripStepPprRequest = id => globalApi.get(`${END_POINT}/${id}/ppr`)

// GENDEC
export const patchTripStepEventController = (tripStepId, gendec, message) => globalApi.patch(`${END_POINT}/${tripStepId}/gendec`, gendec, { message })
export const fetchTripStepGendecRequest = (tripStepId, params) => globalApi.get(`${END_POINT}/${tripStepId}/gendec`, { params })

// DELETE
export const deleteTripStepDeleteRequest = (tripStepId, message, vital, customText) => fireDeleteAlert(vital, customText).then(
  isConfirmed => isConfirmed
    && globalApi.delete(`${END_POINT}/${tripStepId}/delete`, {
      message,
    }),
)
