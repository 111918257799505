<template>
  <!-- NOTE: text/value-field = key/value by default if 'options' is an array -->
  <b-overlay :show="overlay" :variant="$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined">
    <b-form-group :label="label + `${required ? ' *' : ''}`" :label-for="id">
      <b-form-radio-group
        :id="id"
        class="uppercase"
        :checked="value"
        text-field="value"
        :required="required"
        :disabled="disabled"
        @input="$emit('input', $event)"
      >
        <b-form-radio v-for="option in options" :key="option.value" :value="option.value">
          <span v-if="enumClass">{{ getOptionLabel(option) }}</span>
          <span v-else>{{ option.text }}</span>
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  </b-overlay>
</template>

<script>
import { mapActions } from 'vuex'
import { snakeCase } from 'lodash'

// TODO: Improve visibility of this doc

/**
 * @example
 * <enum-radio
 *  id="productTypology"
 *  :value="serviceData.typology"
 *  enum-class="ProductTypology"
 *  :label="$t('service.type')"
 *  required
 *  @change="$emit('input', { ...serviceData, typology: $event })"
 * />
 */

export default {
  name: 'EnumRadio',

  props: {
    value: {
      type: String,
      default: '',
    },
    enumClass: {
      type: String,
      required: false,
      default: null,
    },
    values: {
      type: Array,
      required: false,
      default: null,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'enumRadio',
    },
    label: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: 'value',
    },
    transformText: {
      type: Function,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    options() {
      if (this.enumClass) {
        return this.$store.state.enum[this.enumClass]
      }

      return this.values
    },
  },

  mounted() {
    // Once request even if others <enum-radio are mounted in same time
    if (!this.$store.state.enum[`${this.enumClass}Loading`]) {
      this.fetchEnum2(this.enumClass)
    }
  },

  methods: {
    ...mapActions('enum', ['fetchEnum2']),

    /**
     * Render the translation value if available
     */
    getOptionLabel(currentEnum) {
      const translation = (text, snakeCaseText = false) => {
        /* There is no translation for this text on API */

        // Override the transformation of the render text
        if (this.transformText) {
          return this.transformText(text)
        }
        // If translation key exists on vue-i18n, return this translation
        if (this.$te(`enum.${snakeCase(this.enumClass)}.${!snakeCaseText ? text : snakeCase(text)}`)) {
          return this.$t(`enum.${snakeCase(this.enumClass)}.${!snakeCaseText ? text : snakeCase(text)}`)
        }
        // Else, return the brut text
        return text
      }

      // Classic enum, without translation api. Automatic translation if no `transformText()` prop
      if (typeof currentEnum[this.text] === 'string') {
        return translation(currentEnum[this.text])
      }
      // Only for 'FuelType' | Format: { value: 3, text: 'Coffee' }
      if (typeof currentEnum[this.text] === 'number') {
        return translation(currentEnum.text, true)
      }
      // Translated enum on API. return `transformText(translatedApiText)` or `translatedApiText`
      if (currentEnum[this.text]) {
        const target = this.$options.filters.trans(currentEnum[this.text].name)
        if (this.transformText) return this.transformText(target)
        return target
      }
      // Default return, but never used in good utilisation
      return ''
    },
  },
}
</script>
